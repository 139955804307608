@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,900&display=swap");

* {
  font-family: "Nunito", sans-serif;
}

#root {
  min-height: 100vh;
}
body {
  background: white;
}
.p-sidebar-header {
  display: none !important;
}
.p-sidebar-content{
  padding: .015rem !important;
}

 .menu {
	 list-style: none;
	 margin: 0;
	 padding: 0;
	 width: 100%;
	 height: 3rem;
	 margin: auto;
	 position: relative;
   /* background-color: #26738a; */
	 background: #26738a;
	 border-radius: 0.5rem 0.5rem 0 0;
	 z-index: 7;
}
 .menu li {
	 float: left;
	 width: 25%;
	 height: 100%;
	 margin: 0;
	 padding: 0;
}
 .menu a {
	 display: flex;
	 width: 100%;
	 height: 100%;
	 justify-content: center;
	 align-items: center;
	 color: #fff;
	 text-decoration: none;
	 position: relative;
	 font-size: 18px;
	 z-index: 9;
}
 li.slider {
	 width: 25%;
	 height: 100%;
	 position: absolute;
	 left: 0;
	 top: 0;
	 /* background-color: #e74c3c; */
	 z-index: 8;
	 transition: left .4s, background-color .4s;
}
 .menu li:nth-child(1):hover ~ .slider, .menu li:nth-child(1):focus ~ .slider, .menu li:nth-child(1):active ~ .slider {
	 left: 0;
	 background-color: #3498db;
   border-radius: 0.5rem 0 0 0;
}
 .menu li:nth-child(2):hover ~ .slider, .menu li:nth-child(2):focus ~ .slider, .menu li:nth-child(2):active ~ .slider {
	 left: 25%;
	 background-color: #9b59b6;
}
 .menu li:nth-child(3):hover ~ .slider, .menu li:nth-child(3):focus ~ .slider, .menu li:nth-child(3):active ~ .slider {
	 left: 50%;
	 background-color: #e67e22;
}
 .menu li:nth-child(4):hover ~ .slider, .menu li:nth-child(4):focus ~ .slider, .menu li:nth-child(4):active ~ .slider {
	 left: 75%;
	 background-color: #16a085;
   border-radius: 0 0.5rem 0 0
}
.head-text {
  position: relative;
}
.vip-card {
  background-image: linear-gradient(-20deg, #0d5e76 0%, #6aa7ab 100%);
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 0.5rem 0.5rem 0 0;
  line-height: 1.6;
}

.vip-card .music-pack {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vip-card .music-pack .title {
  font-size: 16px;
  font-weight: 800;
}

.vip-card .music-vip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.card-listing-status{
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 70%;
}
.card-listing-status.setting-card {
  margin-top: 30px;
}

.card-listing {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-listing .item {
  display: flex;
  align-items: center;
  line-height: 40px;
  position: relative;
  overflow: hidden;
}

.card-listing .item:last-child::after {
  display: none;
}

.card-listing .item::after {
  content: "";
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 20px;
}

.card-listing .item .title {
  margin-right: auto;
  margin-left: 8px;
}

.card-listing.setting-card {
  margin-top: 30px;
}

.title-desc {
  font-size: 12px;
  margin-right: 8px;
}

.modal-confirm .modal-content {
	padding: 20px;
	border-radius: 5px;
	border: none;
	font-size: 14px;
}
.modal-confirm .modal-body {
	color: #4c4c4c;
}
.modal-confirm .btn, .modal-confirm .btn:active {
	color: #fff;
	border-radius: 4px;
	background: #60c7c1;
	text-decoration: none;
	transition: all 0.4s;
	line-height: normal;
	min-width: 120px;
	border: none;
	min-height: 40px;
	border-radius: 3px;
	margin: 0 5px;
}
.modal-confirm .btn-secondary {
	background: #c1c1c1;
}
.modal-confirm .btn-secondary:hover, .modal-confirm .btn-secondary:focus {
	background: #a8a8a8;
}
.modal-confirm .btn-danger {
	background: #f15e5e;
}
.modal-confirm .btn-danger:hover, .modal-confirm .btn-danger:focus {
	background: #ee3535;
}
.table {
  margin: 0 0 40px 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: table;
}
.row {
  display: table-row;
  background: #f6f6f6;
}
.row:nth-of-type(odd) {
  background: #e9e9e9;
}
.row.header {
  font-weight: 900;
  color: #ffffff;
  background: #ea6153;
}
.row.blue {
  background: #2980b9;
}
.cell {
  padding: 6px 12px;
  display: table-cell;
}
.fab-container {
  z-index: 999;
  bottom: 18vh !important;
  position: fixed;
  margin: 1em;
  right: 72vw !important;
}

.fab-item{
  width: 40px !important;
  height: 40px !important;
  background: rgb(54 135 148) !important
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}