.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 80px;
    height: 30px;
    /* background: #9e9e9e; */
    background-image: linear-gradient( 88deg, #bbbbbb 6.5%,#2e8da9 93.2% );
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
   
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 1px;
    left: 2px;
    width: 29px;
    height: 28px;
    border-radius: 30px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    
  }
  .fontSizeScale{
    font-size: 1vw;
  }
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  .react-switch-label:active .react-switch-button {
    width: 60px;
  }