.preview-list{
    height: 21rem;
    width: 100%;
    margin: auto;
    background: linear-gradient(180deg, #015b6c, #64b9c9);
    color: white;
}

.preview-data{
    position:absolute; 
    /* top:0;  */
    right:10%;
}

.my-snack {
    width: 100%;
    height: 100%;
    /* margin-top: 22%; */
    background: white;
    cursor: pointer;
    transform: translateY(0);
    /* position: fixed; */
    bottom: -500px;
  }
  
  .my-snack.show {
    /* animation: fadein 0.5s forwards, fadeout 0.5s 2s; */
  }
  
  .my-snack.show:not(.hide):hover {
    animation-play-state: paused;
  }
  
  .my-snack.hide {
    animation-duration: 0s, 2s;
    animation-delay: 0s, 0.5s;
  }
  
  /*Animations to fade the snackbar in and out*/
  @keyframes fadein {
    from {bottom: -500px;}
    to {bottom: 0;}
  }
  
  @keyframes fadeout {
    from {bottom: 0;}
    to {bottom: -500px; opacity: 0;}
  }