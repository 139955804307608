.view-card-grid-view {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3%;
  height: 130%;
  flex-direction: row;
  overflow-y: scroll;
}

.view-App {
  margin-top: -0.8rem;
}

.view-box {
  width: 85%;
  max-width: 85%;
  background-color: #f0f0f0;
  margin-top: 5%;
  margin-bottom: 5%;
  margin: 5px;
}

.view-grid-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #6aa7ab;
  color: white;
  height: 30px;
  padding: 0 10px;
}

.view-card-list-view {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 1rem;
  width: 19rem;
}

.view-list-box {
  width: 98%;
  max-width: 400px;
  margin: 0 auto;
  height: 13.7rem;
  overflow-y: scroll;
}

.view-list-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: #6aa7ab;
  color: white;
  height: 30px;
  padding: 0 10px;
  margin: 10px auto -1%;
}

.view-list-icons-right {
  position: absolute;
  right: 9px;
}

.view-no-preview {
  background-color: white;
  height: 15.7rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.content-child {
  background-color: white;
  height: 13.3rem;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin: 5px 0px;
  box-shadow: black 0px 0px 1px 0px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  padding: 3px;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.content-preview {
  width: 100%;
  background-color: white;
}

.content-role-box {
  width: 98%;
  max-width: 400px;
  margin: 0 auto;
  height: 11rem;
  margin-bottom: -4%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.content-role-header-name {
  background-color: #6aa7ab;
  justify-content: center;
  display: flex;
}

.content-role-data {
  display: flex;
  background-color: #e5e7eb;
  color: black;
  justify-content: center;
  align-items: center;
  /* font-weight: 800; */
  height: 3rem;
}

.parent-button {
  left: 4%;
  margin-top: 0%;
  font-size: 1rem;
  font-weight: bolder;
}

.dropdown-icon-parent {
  display: flex;
  position: absolute;
  font-size: 1.5rem;
  right: 5%;
}

.dropdown-icon-paren {
  font-weight: normal;
}

.back-icon {
  font-size: 1.5rem;
}

.content-role-child-communication {
  width: 92%;
  color: white;
  text-align: justify;
  margin: 0.7rem;
}

.content-role-data-communication {
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  background-color: #e5e7eb;
  color: black;
  flex-flow: column;
  align-items: start;
}

.list-content-preview-communication {
  background-color: white;
  margin: auto auto 5%;
  width: 18.6rem;
  height: 18vh;
  margin-bottom: 5%;
  padding: 0.3rem;
}

.list-content-preview-roles {
  background-color: white;
  margin: auto auto 5%;
  width: 18.6rem;
  height: 18vh;
  margin-bottom: 5%;
  padding: 0.3rem;
}

.content-role-child-parent {
  width: 30%;
  text-align: justify;
  margin: 0.7rem;
}

.content-role-child-subchild {
  /* width: 70%;
  text-align: justify;
  margin: 0.7rem; */
  width: 92%;
  color: white;
  text-align: justify;
  margin: 0.7rem;
}

.content-role-data-subchild {
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  background-color: #e5e7eb;
  color: black;
  flex-flow: column;
  align-items: start;
  padding: 0.3rem;
}

.view-no-preview-no-data{
  background-color: white;
  height: 4rem;
  width: 18rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}