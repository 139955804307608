@media (max-width: 576px) {
    .button-add-all {
        margin-right: -10rem;
    }
}

@media(max-width: 354px) {
    .button-add-all {
        margin-right: 0rem;
    }
}