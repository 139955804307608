@import url("https://fonts.googleapis.com/css?family=DM+Sans:500,700&display=swap");

.nav {
    display: -webkit-inline-box;
    display: inline-flex;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    background-color: #fff;
    width: 19rem;
    height: 2rem;
    overflow-x: scroll;
    /* padding: 0 20px; */
    /* border-radius: 40px; */
    /* box-shadow: 0 10px 40px rgba(159, 162, 177, 0.8); */
}

.nav-item {
    color: #83818c;
    /* padding: 20px; */
    text-decoration: none;
    -webkit-transition: .3s;
    transition: .3s;
    /* margin: 0 6px; */
    padding: 0px 7px 1px;
    z-index: 1;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    position: relative;
    /* margin-bottom: 1%; */
    margin-top: 1%;
}

.nav-item:before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 5px;
    /* background-color: #dfe2ea; */
    background-color: gray;
    border-radius: 8px 8px 0 0;
    opacity: 0;
    -webkit-transition: .3s;
    transition: .3s;
}

/* .nav-item.is-active::before {
    opacity: 1;
    bottom: 0;
    background-color: #155f75;
} */

.nav-item:not(.is-active):hover:before {
    opacity: 1;
    /* bottom: 0; */
    margin-bottom: 0.2rem;
}

.nav-item:not(.is-active):hover {
    color: #333;
}

.nav-indicator {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    -webkit-transition: .4s;
    transition: .4s;
    height: 5px;
    z-index: 1;
    border-radius: 8px 8px 0 0;
}

.is-active {
    /* border-radius: 63%; */
    /* border-color: black; */
    /* background-color: #abcb6b; */
    /* margin-top: 1%; */
    border-radius: 0.6rem;
    /* width: 2.9rem; */
    /* border-color: black; */
    background-color: #2c839d;
    color: white;
    /* margin-bottom: 1%; */
}

@media (max-width: 580px) {
    .nav {
        /* overflow: auto; */
    }
}