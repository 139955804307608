.no-preview {
  background-color: white;
  /* width: 100%; */
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.breadcrumb{
  background-color: #155f752b;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    /* position: absolute; */
    /* left: 2%; */
    /* margin-top: 2%; */
    /* font-size: 1rem; */
    width: 100%;
}

.breadcrumb-data{
  color: rgb(21 94 117 / var(--tw-bg-opacity));
  /* text-indent: 20%; */
  /* padding-left: 3rem; */
  width: 3rem;
  font-weight: bold;
  margin: auto;
  justify-content: space-around;
}

.navbar {
  position: absolute;
  margin-top: 16.2rem;
}

.content {
  /* background-color: white;
  height: 15rem;
  display: flex;
  margin: 5px 0px;
  box-shadow: black 0px 0px 1px 0px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  padding: 3px;
  margin-bottom: 0.5rem; */
}

.content-view {
  height: 15.5rem;
  margin-bottom: 0.8rem;
  text-align: left;
}

.dropdown-parent:hover {
  background-color: #e5e7eb;
}

.text-sm {
  line-height: 1.5rem;
}

.dropdown-items {
  margin-left: 7%;
}

.text-on-image {
  position: absolute;
  right: 6rem;
  left: 6rem;
  border: 0.1rem solid #5271a5;
  border-radius: 0.50rem;
  padding-left: 1rem;
  background: #5171a5;
  color: #fff;
}

.floating-action-button {
  position: absolute !important;
  right: 0 !important;
  bottom: 10% !important;
  z-index: 1000;
}

.execute-button{
  bottom: 0rem !important;
}

.information {
  position: absolute;
  right: 5%;
  margin-top: 1%;
  font-size: 1.2rem;
}

.text-on-image-menu {
  width: 100%;
  /* padding-left: 0.5rem; */
}

.card-listing {
  display: flex;
  flex-wrap: wrap;
  height: 14rem;
  box-shadow: none;
}

.card-grid-view {
  display: flex;
  flex-wrap: wrap;
  margin-top: -0.1rem;
  height: 130%;
  flex-direction: row;
  overflow-y: scroll;
}

.card-list-view {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow-y: scroll;
  margin-bottom: 2%;
}

.box {
  width: 85%;
  max-width: 85%;
  /* height: 42%; */
  /* margin: 0 auto; */
  background-color: #f0f0f0;
  margin-top: 5%;
  margin-bottom: 5%;
  margin: 5px;
}

.w-full {
  width: 100%;
}

.list-box {
  width: 98%;
  max-width: 400px;
  margin: 0 auto;
  height: 16rem;
  margin-bottom: -4%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #6aa7ab;
  color: white;
  height: 30px;
  padding: 0 10px;
}

.list-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: #6aa7ab;
  color: white;
  height: 30px;
  padding: 0 10px;
  margin: 10px auto -1%;
}

.card-listing.setting-card {
  margin-top: 100px;
  overflow-y: scroll;
}

.footer {
  text-align: left;
  background-color: #6aa7ab;
  color: white;
}

.floating-switch-button {
  position: absolute;
  right: 10%;
  margin-top: -3%;
}

.dropdown-arrow {
  position: absolute;
  right: 5%;
  margin-top: 0%;
  font-size: 1.5rem;
  display: flex;
}

/* .content-child {
  background-color: white;
  height: 12.8rem;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin: 5px 0px;
  box-shadow: black 0px 0px 1px 0px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  padding: 3px;
  margin-bottom: 0.5rem;
  margin-top: 2.5rem;
} */

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: lightgray;
  border-radius: 1rem;
  height: 2rem;
  width: 100%;
  padding: 0 10px;
}


.content-list-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: #6aa7ab;
  color: white;
  height: 30px;
  padding: 0 10px;
  margin: 10px auto -1%;
}

.content-role-child {
  width: 25%;
  color: white;
  /* margin: auto; */
  /* padding: 10px; */
  text-align: justify;
  /* margin-top: 20px; */
  margin: 0.7rem;
  /* margin-bottom: 5%; */
}

.role-arrow-value {
  /* position: absolute; */
  left: 5%;
  margin-top: 0%;
  margin-left: 3%;
  font-size: 1rem;
  font-weight: bolder;
}

.role-cancle {
  position: absolute;
  right: 5%;
  margin-top: 2%;
  font-size: 1rem;
  font-weight: bolder;
}

/* .content-preview {
  width: 100%;
  background-color: white;
} */

.main-button{
  /* margin-top: -0.4rem; */
}

.search-input {
  border-radius: 1rem;
  width: 11rem;
  text-indent: 0.5rem;
}

.arrow-value {
  position: absolute;
  left: 4%;
  margin-top: 0%;
  font-size: 1rem;
  font-weight: bolder;
}

.preview-icon {
  position: absolute;
  left: 30%;
  margin-top: 2%;
  font-size: 1.1rem;
}

.icons-right {
  font-size: 20px;
}

.icons-left {
  font-size: 20px;
}

.head-icon {
  position: absolute;
  right: 15px;
  margin-top: 60px;
}

.gap-3 {
  overflow: hidden;
}

.list-icons-right {
  position: absolute;
  right: 9px;
}

.fab-item {
  width: 40px !important;
  height: 40px !important;
  background: rgb(54 135 148) !important;
  margin: 10px auto 0px !important;
}

.fab-container:hover .fab-item:not(:last-child) {
  margin: 10px auto 0px !important;
}

/* Media Queries for Responsiveness */

/* For phones */
@media (max-width: 576px) {
  .box {
    /* max-width: 100%; */
    width: 100%;
  }

  .head-text {
    /* height: 46vh;
    overflow-y: scroll; */
  }

  .card-grid-view {
    /* width: 52%; */
  }
}

@media(max-width: 354px) {
  .head-btn {
    display: flex;
  }

  .head-icon {
    margin-top: 0% !important;
  }

  /* .card-listing.setting-card{
    margin-top: 0% !important;
  } */
  .card-listing .setting-card .text-on-image-menu .contentview {
    margin-top: 0% !important;
    background: transparent;
  }
}

@media(min-height: 391px) {
  .head-btn {
    display: flex;
  }

  .head-icon {
    margin-top: 0% !important;
  }

  /* .card-listing.setting-card{
    margin-top: 0% !important;
  } */
  .card-listing,
  .setting-card,
  .text-on-image-menu {
    margin-top: 12% !important;
  }

  .contentview {
    margin-top: 0% !important;
    background: transparent;
  }

  .preview {
    margin-top: 0% !important;
  }
}

/* For tablets */
@media (min-width: 577px) and (max-width: 992px) {
  .box {
    /* max-width: 80%; */
  }
}

/* For desktop */
@media (min-width: 993px) {
  .box {
    /* max-width: 60%; */
  }
}