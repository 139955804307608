.user-form-parent {
    height: 30rem;
    align-items: flex-start;
}

.user-form {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.text-address {
    width: 20rem;
    height: 5rem;
    margin-left: 2rem;
}

.email {
    width: 12rem;
    margin-left: 2rem;
}

.mobileNumber {
    width: 12rem;
    margin-left: 2rem;
}

.parents_number {
    width: 12rem;
    margin-left: 2rem;
}

.name {
    width: 12rem;
    margin-left: 2rem;
}

.submit-btn {
    margin-left: 2rem;
}

@media (max-width: 576px) {
    .user-form-parent {
        height: 26rem;
        width: 20rem;
    }

    .main-layout {
        margin-top: -7rem;
    }

    .text-address {
        width: 15rem;
    }
}

@media(max-width: 354px) {}