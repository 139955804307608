.list-users{
    position:absolute;
    right: 16.5rem;
}

@media (max-width: 576px) {
    .list-users{
        /* position:absolute; */
        /* right: 4.5rem; */
    }
  }
  
  @media(max-width: 354px) {
    .list-users{
        /* position:absolute; */
        /* right: -16rem; */
    }
  }