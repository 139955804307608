.all-buttons{
    display: flex;
    flex-wrap: wrap;
}

.dropdown-parents:hover{
    background-color: lightgray;
}

.dropdown-item{
    margin-left: 7%;
}
.dropdown{
    right: 0;
}

.dropdown-arrows{
    /* position: absolute; */
    right: 1%;
    margin-top: 0%;
    font-size: 2rem;
}

.child-button-undo{
    width: 3rem;
}

.child-button-redo{
    width: 3rem;
}

.child-button-save{
    width: 6rem;
}

.child-button-preview{
    width: 7rem;
}

.child-button-addroles{
    width: 7rem;
}

.child-button-addsubtask{
    width: 7rem;
}
  
@media (max-width: 576px) {
    .all-buttons{
        gap: 2%;
        height: 7rem;
        overflow: none !important;
        max-height: 6rem;
        width: 17rem;
        display: flex;
        flex-wrap: wrap;
    }
    .dropdown{
        right: auto;
    }
    .child-taskfield{
        width: 10rem;
    }
    .child-button-save{
        height: 2.5rem;
    }
    .child-button-undo{
        height: 2.5rem;      
    }
    .child-button-redo{
        height: 2.5rem;
    }
    .dropdown-arrows {
        /* position: absolute; */
        right: 2%;
        margin-top: 0%;
        font-size: 2rem;
    }
}

@media (max-width: 409px){
    .all-buttons{
        gap: 2%;
        height: 7rem;
        overflow: none !important;
        max-height: 6rem;
        width: 17rem;
        display: flex;
        flex-wrap: wrap;

    }
    .dropdown-arrows {
    /* position: absolute; */
    right: 2%;
    margin-top: 0%;
    font-size: 2rem;
    }
}
@media(max-width: 354px){
    .all-buttons{
        gap: 2%;
        height: 7rem;
        overflow: none !important;
        max-height: 6rem;
        width: 14rem;
        display: flex;
        flex-wrap: wrap;
    }
    .dropdown{
        right: auto;
    }
    .child-button-save{
        height: 2.5rem;
    }
    .child-button-undo{
        height: 2.5rem;      
    }
    .child-button-redo{
        height: 2.5rem;
    }
    .dropdown-arrows {
        /* position: absolute; */
        right: 6%;
        margin-top: 1%;
        font-size: 2rem;
    }
    .dropdown-parents:hover{
        background-color: lightgray;
    }
}